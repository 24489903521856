import { useEffect } from "react";
import classes from "./AboutUsEB.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

// let Blessing = 'https://res.cloudinary.com/skycoder/image/upload/v1708609506/Blessing_Ekong_qgs6qt.jpg';
// let Azeez = 'https://res.cloudinary.com/skycoder/image/upload/v1708609505/Azeez_Bello_fe0yyv.jpg';
// let Daniel = 'https://res.cloudinary.com/skycoder/image/upload/v1708609505/Daniel_Opadiran_kd6obl.jpg';
// let Miracle = 'https://res.cloudinary.com/skycoder/image/upload/v1708609504/Miracle_Ihejimba_kzmc61.jpg';
// let Mercy = 'https://res.cloudinary.com/skycoder/image/upload/v1708609505/Mercy_Jacob_wtfesh.jpg';
// let Kunle = 'https://res.cloudinary.com/skycoder/image/upload/v1708609505/Olakunle_Soji-Oke_skpn26.jpg';
let Mercy = 'https://res.cloudinary.com/dof4l5eul/image/upload/v1732892466/Mercy_Jacob_-_MCP_wiaaqc.jpg';
let Ola = 'https://res.cloudinary.com/skycoder/image/upload/v1708609504/Olaotan_Oyenuga_lsz96o.jpg';
let Cynthia = 'https://res.cloudinary.com/dof4l5eul/image/upload/v1732892462/Cynthia_Anna_David_MCVP_MX_OD_agiapt.jpg';
let Adedayo = 'https://res.cloudinary.com/dof4l5eul/image/upload/v1732892465/Oseni_Adedayo_MCVP_F_L_bfhpny.jpg';
let Susan = 'https://res.cloudinary.com/dof4l5eul/image/upload/v1732892469/Susan_Ogri-MCVP_ICX_md9aov.jpg';
let Samson = 'https://res.cloudinary.com/dof4l5eul/image/upload/v1732892471/Samson_Asemanya_-_MCVP_oGX_qefbbn.jpg';
let Liz = 'https://res.cloudinary.com/dof4l5eul/image/upload/v1732892908/Liz_1_ob2hc1.jpg';



const AboutUsEB = () => {
  const executiveBoard = [
    {
      name: "Mercy Jacob",
      role: "Country Director",
      image: Mercy,
    },
    {
      name: "Susan Ogri",
      role: "Programs Manager",
      image: Susan,
    },
    {
      name: "Elizbeth Ekanem",
      role: "Partnerships Manager",
      image: Liz,
    },
    {
      name: "Oseni Adedayo",
      role: "Finance Manager",
      image: Adedayo,
    },
    {
      name: "Samson Asemanya",
      role: "Product Operations Manager",
      image: Samson,
    }, 
    {
      name: "Olaotan Oyenuga",
      role: "Marketing Manager",
      image: Ola,
    },
    {
      name: "Cynthia Anna David",
      role: "Human Resource Manager",
      image: Cynthia,
    },
   
  ];

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section className={classes.container}>
      <div className={classes.header} data-aos="fade-up">
        We are led by the{" "}
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="11"
            viewBox="0 0 162 11"
            fill="none"
          >
            <path
              d="M1 10C37.7712 10 74.2887 3.09566 111.21 2.83709C126.523 2.72985 141.695 2.64292 156.984 2.83709C161.126 2.88969 160.943 2.11566 156.798 2.04119C149.442 1.90899 142.68 0.703723 135.165 1.06845C121.289 1.74189 107.367 1.2599 93.4762 1.86433C65.1485 3.09692 36.7924 2.63568 8.52067 3.63296"
              stroke="#037EF3"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
          <span>right people</span>
        </span>{" "}
        with the right skills
      </div>
      <div className={classes.ebInfoSection}>
        {executiveBoard.map((data, i) => {
          return (
            <div key={i} className={classes.ebPerson} data-aos="fade-up">
              <img src={data.image} alt={data.name} loading="lazy" />
              <div>
                <h6>{data.role}</h6>
                <p>{data.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default AboutUsEB;

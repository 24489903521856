import wavy from '../../Assets/Images/wavyRecruitment.svg';

const RegistrationSteps = () => {
  const steps = [
    {
      number: '01',
      title: 'FILL AND SUBMIT APPLICATION',
      description:
        'Adequately fill out the form with your details and submit your application.',
    },
    {
      number: '02',
      title: 'GO THROUGH APPLICATION PROCESS',
      description:
        'A representative of the local team you select would reach out to you to guide you through the next steps to becoming a member.',
    },
    {
      number: '03',
      title: 'WELCOME ONBOARD',
      description:
        'Once you successfully complete the application and selection process, you would be officially welcomed into one of our local teams. Your leadership experience starts now!',
    },
  ];

  return (
    <div className="bg-white mt-14 mb-20">
      <div className="flex flex-col items-center gap-8 md:my-8">
        <div className="bg-[#037ef3] w-full py-8">
          <h2 className="text-5xl font-extralight italic text-center text-white">
            Join in <span className="font-bold not-italic">3 steps</span>
          </h2>
        </div>
        <div className="relative w-full flex flex-col items-center my-20 px-4 md:px-0">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`relative flex items-center w-full md:w-3/4 lg:w-1/2 ${
                index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'
              }`}
            >
              {/* Step Details */}
              <div
                className={`flex w-[48.5%] ${
                  index % 2 === 0 ? 'pl-6 text-left' : 'pr-6 text-right'
                }`}
              >
                <div>
                  <h3 className="text-base font-bold leading-6 text-[#20252F]">
                    {step.title}
                  </h3>
                  <p className="font-light italic text-xl leading-8 text-[#1D2129]">
                    {step.description}
                  </p>
                </div>
              </div>
              {/* Step Number and Line */}
              <div className="flex flex-col items-center">
                <div className="">
                  <span className="self-start text-blue-600 text-lg font-bold">
                    {step.number}
                  </span>
                </div>
                {/* Vertical Line */}
                {index < steps.length - 1 && (
                  <div className="h-32 border-l border-gray-300"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center gap-4 w-[90%] mx-auto">
        <p className="text-xl leading-6 text-[#20252F] font-light italic text-center">
          <span className="text-[#2F80ED] font-bold not-italic">NOTE:</span> You
          would be required to pay a{' '}
          <span className="font-normal not-italic">Membership Fee </span> during
          your application process.
        </p>
        <img src={wavy} alt="wavy" />
      </div>
    </div>
  );
};

export default RegistrationSteps;

// import { useState } from 'react';
import classes from './FAQDropdown.module.css';

const FAQDropdown = ({
  header,
  content,
  skills,
  image,
  isActive,
  onToggle,
}) => {
  // States
  // const [displayDropDown, setDisplayDropdown] = useState(false);
  return (
    <div className={classes.container}>
      <div
        className={`${classes.dropdownHeader} ${
          isActive ? classes.active : ''
        }`}
        onClick={onToggle}
      >
        <p className="pl-5">{header}</p>
        <div>
          {!isActive ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M15 20L25 20"
                stroke={isActive ? '#fff' : '#000'}
                stroke-width="2"
              />
              <path
                d="M20 25V15"
                stroke={isActive ? '#fff' : '#000'}
                stroke-width="2"
              />
              {/* <circle cx="20" cy="20" r="19.5" stroke="black" /> */}
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M15 20L25 20"
                stroke={isActive ? '#fff' : '#000'}
                stroke-width="2"
              />
              {/* <circle cx="20" cy="20" r="19.5" stroke="black" /> */}
            </svg>
          )}
        </div>
      </div>
      <div
        className={`${classes.dropdownContainer} `}
        style={isActive ? { maxHeight: '400px' } : { maxHeight: '0px' }}
      >
        {image && <img src={image} alt="roles" className="w-full" />}
        <div className="pl-5">
          <p>{content}</p>
          {skills && (
            <p className="font-semibold leading-8">
              Skills you can gain: <span className="font-normal">{skills}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQDropdown;

import { useEffect, useState } from 'react';
import FAQDropdown from '../../Components/FAQDropdown/FAQDropdown';
import classes from './MembershipRole.module.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import programManagement from '../../Assets/Images/programManagementRole.png';
import marketing from '../../Assets/Images/marketingRole.png';
import BD from '../../Assets/Images/BDRole.png';
import finance from '../../Assets/Images/financeRole.png';
import HR from '../../Assets/Images/HRRole.png';
import sales from '../../Assets/Images/salesRole.png';

const MembershipRole = () => {
  const FAQs = [
    {
      header: 'PROGRAM MANAGEMENT',
      image: programManagement,
      content:
        "Support youth from your country/territory to connect with our experiences abroad. You'll work with AIESEC offices overseas and help guide young people who choose to take up an AIESEC exchange experience.",
      skills: 'Sales, customer service, communication, project management',
    },
    {
      header: 'MARKETING',
      image: marketing,
      content:
        "Use your creativity and analytical skills to create promotional campaigns to attract customers to our programs, events, and initiatives. You'll analyze customer insights, brainstorm ideas, and collaborate to make them happen - all to better project our brand, reach, and impact.",
      skills: 'Brand management, data analysis, social media',
    },
    {
      header: 'PARTNERSHIPS & BUSINESS DEVELOPMENT',
      image: BD,
      content:
        "Work on corporate or non-corporates sales by engaging local companies and organizations to create internships, projects, or events for youth. You'll manage client relationships, learn how organizations work, and help interns settle in your country/territory.",
      skills: 'Sales, customer service, project management',
    },
    {
      header: 'FINANCE',
      image: finance,
      content:
        "Play a critical role in ensuring our local operational strategies are executed effectively and efficiently. You'll monitor finances, provide investment strategies, ensure we are complying with the law and our internal policies, and make sure we'll still be here in the future.",
      skills: 'Strategic planning, budgeting, resources management',
    },
    {
      header: 'HUMAN RESOURCES',
      image: HR,
      content:
        "It's only by investing in finding and developing the best in our people that we can grow as an organization. You'll design training and development plans, track performance, and create systems that celebrate achievement as well as recruit new people into your local team.",
      skills: 'communication skills, HR processes management, recruitment',
    },
    {
      header: 'SALES OPERATION',
      image: sales,
      content:
        "Work on corporate or non-corporates sales by engaging local companies and organizations to create internships, projects, or events for youth. You'll manage client relationships, learn how organizations work, and help interns settle in your country/territory.",
      skills: 'Sales, customer service, project management',
    },
  ];

  const [activeDropdown, setActiveDropdown] = useState(null);

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const handleDropdownToggle = (index) => {
    setActiveDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className={classes.container}>
      <div className={classes.dropdownSection}>
        {FAQs.map((data, i) => {
          return (
            <div key={i} data-aos="fade-down">
              <FAQDropdown
                header={data.header}
                image={data.image}
                content={data.content}
                skills={data.skills}
                isActive={activeDropdown === i}
                onToggle={() => handleDropdownToggle(i)}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default MembershipRole;
